import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { IVSFWishlist, KEY as Wishlistkey } from 'theme/@types/vsf/stores/wishlist';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';
import { StoreConfig } from 'theme/stores/storeConfig/storeConfig';
import { FbqConversionApiStore } from 'theme/stores/fbqConversionApi/fbqConversionApi';
import { getFBQConversionData, getFBQEventId } from '@zento-lib/components/AnalyticsServices/helpers';

import { Button } from '../../atom/Button/Button';
import type { Effect } from '../../helpers/effects';

import { IAddToWishlist } from './AddToWishlist.d';
import style from './style.scss';

const wishlistStore = namespace<IVSFWishlist>(Wishlistkey);
const appContextStore = namespace<AppContextStore>(appContextKey);

/**
 * Add to Wishlist
 *
 * Renders a button which allows end users to add or remove a product from wishlist
 */
@Component({})
export class AddToWishlist extends BaseComponent<IAddToWishlist, unknown> {
  private static T = {
    addToWishlist: 'add_to_wishlist',
    removeFromWishlist: 'remove_from_wishlist',
  };

  /**
   * Current product
   */
  @Prop({ type: Object, required: true })
  product: IProductState;

  /**
   * Checks if the current product is part of the cart items
   */
  @Prop({ type: Boolean, default: false })
  cartProduct?: boolean;

  /**
   * Determines if button style effect is applied
   */
  @Prop({ type: [String, Boolean], default: false })
  addEffect?: Effect;

  /**
   * Determines product add to styling
   */
  @Prop({ type: Boolean, default: false })
  addToStyle?: boolean;

  @wishlistStore.State('items')
  items: IVSFWishlist['state']['items'];

  @wishlistStore.State('loaded')
  loaded: IVSFWishlist['state']['loaded'];

  @appContextStore.Getter('isServer')
  protected isServer: boolean;

  private storeConfig = new StoreConfig();
  private fbqConversionApiStore = new FbqConversionApiStore();

  data() {
    return {
      enabled: this.extended.$config.zento.wishlist?.enabled ?? true,
    };
  }

  get isOnWishlist(): boolean {
    return !!this.items.find((item) => item.sku === this.product.sku) || false;
  }

  addToWishlist(product: IProductState) {
    if (this.loaded) {
      if (!this.isServer && product) {
        this.broadcast('analytics/product-wishlist-track', { product, id: { event_id: this.eventId } });
        this.broadcast('analytics/product-wishlist-track-custom', { product, id: { event_id: this.eventId } });

        if (this.storeConfig.storeConfigurations.facebookConversionGeneralActive) {
          this.fbqConversionApiStore.send(
            getFBQConversionData({
              items: product,
              eventName: 'AddToWishlist',
              eventId: this.eventId,
              sourceUrl: product.url_path.startsWith('/') ? product.url_path : '/' + product.url_path,
              content_name: product.name,
              content_category: product.category?.map((cat) => cat.name).join(', '),
            }),
          );
        }
      }

      return this.$store.dispatch('wishlist/addItem', product);
    }

    return false;
  }

  removeFromWishlist(product: IProductState) {
    return this.loaded ? this.$store.dispatch('wishlist/removeItem', product) : false;
  }

  wishlistToggle() {
    if (this.isOnWishlist) {
      this.removeFromWishlist(this.product);
    } else {
      this.addToWishlist(this.product);
      if (this.cartProduct) {
        this.$store.dispatch('cart/removeItem', { product: this.product });
      }
    }
  }

  private get eventId() {
    return getFBQEventId('AddToWishlist', [this.product.id]);
  }

  render() {
    const config = this.extended.$config.zento.wishlist;

    return this.$data.enabled ? (
      <Button
        styleType={config.buttonStyle}
        effect={this.addEffect}
        handler={this.wishlistToggle}
        dataTooltip={
          !this.isOnWishlist
            ? {
                id: config.buttonLabel.length ? config.buttonLabel : AddToWishlist.T.addToWishlist,
              }
            : { id: AddToWishlist.T.removeFromWishlist }
        }
        ariaLabel={
          !this.isOnWishlist
            ? { id: config.buttonLabel.length ? config.buttonLabel : AddToWishlist.T.addToWishlist }
            : { id: AddToWishlist.T.removeFromWishlist }
        }
        class={{
          [style.addToWishlist]: true,
          [style.isOnWishlist]: this.isOnWishlist,
          [style.addToStyle]: this.addToStyle,
        }}
        dataTestId='addToWishlist'>
        {!this.isOnWishlist
          ? this.getTranslation({ id: config.buttonLabel.length ? config.buttonLabel : AddToWishlist.T.addToWishlist })
          : this.getTranslation({ id: AddToWishlist.T.removeFromWishlist })}
      </Button>
    ) : null;
  }
}
