import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { IVSFUser, KEY as UserKey } from 'theme/@types/vsf/stores/user';

import { IProductState } from '../../../../themes/default/@types/vsf/stores/product';
import type { BadgePosition } from '../../types/BadgePosition';

import type { ILabels } from './Labels.d';
import style from './style.scss';

const uiUser = namespace<IVSFUser>(UserKey);

/**
 * Product Labels
 *
 * Product Labels component allowing to create labels for products.
 **/
@Component({})
export class Labels extends BaseComponent<ILabels, unknown> {
  private static T = {
    productLabelNew: 'product_label_new',
    productLabelSale: 'product_label_sale',
  };

  /**
   * Determines Sale Label
   */
  @Prop({ type: Object, required: false })
  product?: IProductState & {
    finalPriceInclTax?: any;
    special_from_date?: string;
    special_to_date?: string;
    finalPrice?: string;
    max_price?: any;
    max_price_incltax?: string;
    min_price?: any;
    min_price_incltax?: any;
    minimal_price_incltax?: string;
    price_incltax?: string;
    special_price_incltax?: any;
    regular_price?: any;
    regular_price_incltax?: string;
    final_price_incl_tax?: any;
    price_groups?: any;
    specialPrice?: any;
  };

  /**
   * Badge position
   */
  @Prop({ type: String, required: false, default: 'initial' })
  badgePosition?: BadgePosition;

  @uiUser.Getter('isLoggedIn')
  protected isLoggedIn: boolean;

  data() {
    return {
      discountPrice: 0,
    };
  }

  private isSpecialPriceActive(fromDate, toDate) {
    const now = new Date();
    fromDate = fromDate ? new Date(fromDate) : false;
    toDate = toDate ? new Date(toDate) : false;

    if (fromDate && toDate) {
      return fromDate < now && toDate > now;
    }

    if (fromDate && !toDate) {
      return fromDate < now;
    }

    if (!fromDate && toDate) {
      return toDate > now;
    }
  }

  /**
   * Determines sale label
   */
  get isOnSale() {
    const product = this.product;
    let price = 0;
    let specialPrice = 0;
    let prodIsOnSale = false;

    if (product.type_id === 'bundle' && product.min_price > 0) {
      price = product.min_price;
      specialPrice = product.original_special_price;
    } else {
      price = product.regular_price ? parseFloat(product.regular_price) : parseFloat(product.price);

      if (product.type_id === 'configurable' && 'min_price' in product) {
        specialPrice =
          parseFloat(product.min_price) !== parseFloat(product.price)
            ? parseFloat(product.min_price)
            : parseFloat(product.special_price);
      } else {
        specialPrice =
          parseFloat(product.finalPrice) !== parseFloat(product.price)
            ? parseFloat(product.finalPrice)
            : parseFloat(product.special_price);
      }

      if (
        'special_from_date' in product &&
        product.special_from_date !== null &&
        this.isSpecialPriceActive(product.special_from_date, product.special_to_date)
      ) {
        prodIsOnSale = true;
      } else if (specialPrice > 0 && specialPrice !== price) {
        prodIsOnSale = specialPrice > 0 && specialPrice !== price;
      }
    }

    return product.sale ? product.sale : prodIsOnSale && specialPrice > 0 && specialPrice !== price;
  }

  /**
   * Determines new label
   */
  get isNew() {
    return !!this.product.new;
  }

  /**
   * Determine current product discount in percent
   */
  get discountPercentage() {
    const product = this.product;
    const actualPrice = product.originalPriceInclTax ?? product.price;
    let specialPrice = 0;

    if (product.type_id === 'configurable' && 'min_price' in product) {
      specialPrice =
        parseFloat(product.min_price) !== parseFloat(product.price)
          ? parseFloat(product.min_price)
          : parseFloat(product.special_price);
    } else {
      specialPrice = product.finalPriceInclTax
        ? product.finalPriceInclTax
        : product.priceInclTax
        ? product.priceInclTax
        : product.final_price
        ? product.final_price
        : product.special_price
        ? product.special_price
        : actualPrice;
    }
    let priceDiscountPercentage = '';

    if (
      specialPrice &&
      actualPrice &&
      parseFloat(actualPrice as any) !== 0 &&
      parseFloat(specialPrice as any) !== 0 &&
      parseFloat(specialPrice as any) !== parseFloat(actualPrice as any)
    ) {
      let discount = parseFloat((100 - ((specialPrice as any) / actualPrice) * 100).toPrecision(3));
      discount = Math.round(discount);

      this.$data.discountPrice = discount;

      if (isFinite(discount) && discount > 0) {
        priceDiscountPercentage = '-' + discount + '%';
      }
    } else if (product.type_id === 'bundle') {
      const discount = parseFloat((100 - product.original_special_price).toPrecision(2));

      if (isFinite(discount)) {
        priceDiscountPercentage = '-' + discount + '%';
      }
    }

    return priceDiscountPercentage;
  }

  private userGroupPices() {
    if (this.isLoggedIn && 'price_groups' in this.product) {
      const userGroupId = this.$store.state.user.current.group_id;
      const groupId = Object.keys(this.product.price_groups).find((p) => p === `group_${userGroupId}`);
      const product = this.product;

      if (groupId) {
        const userGroupPrices = this.product.price_groups[groupId];

        if (userGroupPrices.final_price > 0) {
          product.finalPrice = userGroupPrices.final_price;
          product.final_price = userGroupPrices.final_price;
        }
        if (userGroupPrices.final_price_incltax > 0) {
          product.finalPriceInclTax = userGroupPrices.final_price_incltax;
          product.final_price_incl_tax = userGroupPrices.final_price_incltax;
        }
        if (userGroupPrices.max_price > 0) {
          product.max_price = userGroupPrices.max_price;
        }
        if (userGroupPrices.max_price_incltax > 0) {
          product.max_price_incltax = userGroupPrices.max_price_incltax;
        }
        if (userGroupPrices.min_price > 0) {
          product.min_price = userGroupPrices.min_price;
        }
        if (userGroupPrices.min_price_incltax > 0) {
          product.min_price_incltax = userGroupPrices.min_price_incltax;
        }
        if (userGroupPrices.minimal_price_incltax > 0) {
          product.minimal_price_incltax = userGroupPrices.minimal_price_incltax;
        }
        if (userGroupPrices.price_incltax > 0) {
          product.price_incltax = userGroupPrices.price_incltax;
        }
        if (userGroupPrices.regular_price > 0) {
          (product as any).regular_price = userGroupPrices.regular_price;
        }
        if (userGroupPrices.regular_price_incltax > 0) {
          product.regular_price_incltax = userGroupPrices.regular_price_incltax;
        }
        if (userGroupPrices.special_price_incltax > 0) {
          product.special_price_incltax = userGroupPrices.special_price_incltax;
        }
        if (userGroupPrices.final_price < userGroupPrices.price) {
          product.min_price = parseFloat(userGroupPrices.final_price);
          product.min_price_incltax = parseFloat(userGroupPrices.final_price_incl_tax);
          product.specialPrice = parseFloat(userGroupPrices.final_price);
          product.specialPriceInclTax = parseFloat(userGroupPrices.final_price_incl_tax);
          product.special_price_incltax = parseFloat(userGroupPrices.final_price_incl_tax);
        }
      }
    }
  }

  beforeMount() {
    this.userGroupPices();

    this.onBroadcast('user-after-loggedin', () => {
      this.userGroupPices();
    });
  }

  render() {
    const config = this.extended.$config.zento.theme;

    return this.isOnSale || this.isNew ? (
      // TODO: Once we can generate labels than we should add this two labels added in atoms
      // One will be a generic atomic label
      <div
        class={{
          [style.badge]: true,
          [style.topRight]: this.badgePosition === 'topRight',
          [style.bottomLeft]: this.badgePosition === 'bottomLeft',
          [style.bottomRight]: this.badgePosition === 'bottomRight',
          [style.initial]: this.badgePosition === 'initial',
        }}
        key='badge'>
        {!config.hideDiscountLabel && this.isOnSale ? (
          <span class={[style.label, style.saleLabel]} key='sale-label'>
            {config.showDiscountPercentage && this.discountPercentage ? (
              <span>{this.discountPercentage}</span>
            ) : (
              <span>{this.getTranslation({ id: Labels.T.productLabelSale })}</span>
            )}
          </span>
        ) : null}
        {this.isNew ? (
          <span class={[style.label, style.newLabel]} key='new-label'>
            <span>{this.getTranslation({ id: Labels.T.productLabelNew })}</span>
          </span>
        ) : null}
      </div>
    ) : null;
  }
}
